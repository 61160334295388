<template>
  <create-proposal-form></create-proposal-form>
</template>

<script>
import CreateProposalForm from '@/components/forms/CreateProposalForm.vue'

export default {
  components: {
    CreateProposalForm,
  },
}
</script>

<style lang="scss" scoped>

</style>
