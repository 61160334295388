// import singleChoice from '@/utils/voting/singleChoice'
// import approval from '@/utils/voting/approval'
// import quadratic from '@/utils/voting/quadratic'
// import weighted from '@/utils/voting/weighted'

const votingTypes = [
  'Weighted',
  'Single-Choice',
  'Approval',
  'Quadratic',
]

export default votingTypes
